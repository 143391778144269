<!-- src/views/Auth/SSOCallback.vue -->
<template>
  <div class="sso-callback-container">
    <div class="sso-content">
      <div class="loading-spinner"></div>
      <p>Processing SSO login...</p>
    </div>
  </div>
</template>

<style scoped>
.sso-callback-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
}

.sso-content {
  text-align: center;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

p {
  font-size: 18px;
  color: #333;
}
</style>

<script>
import { mapActions } from 'vuex';
import Cookies from 'js-cookie';

export default {
  name: 'SSOCallback',
  methods: {
    ...mapActions('auth', ['login']),
    async handleSSOCallback() {
      // Decode the entire URL to handle any encoding issues
      const decodedUrl = decodeURIComponent(window.location.href);
      const urlParams = new URLSearchParams(new URL(decodedUrl).search);
      
      const param_token = urlParams.get('token');
      const param_id = urlParams.get('id');
      const param_role = urlParams.get('role');
      const param_redirect_path = urlParams.get('redirect_path');

      console.log('SSO Params:', { param_token, param_id, param_role, param_redirect_path });

      if (param_token && param_id && param_role) {
        // Set cookies directly
        Cookies.set("token", param_token, { expires: 7 });
        Cookies.set("id", param_id, { expires: 7 });
        Cookies.set("role", param_role, { expires: 7 });

        try {
          await this.login({
            token: param_token,
            id: param_id,
            role: param_role,
            sso: true
          });
          
          // After successful login, redirect and reload
          setTimeout(() => {
            if (param_redirect_path) {
              window.location.href = param_redirect_path;
            } else {
              // Default redirect if no redirect_path is provided
              window.location.href = '/'; // or any default path
            }
          }, 100);
        } catch (error) {
          console.error('SSO login failed', error);
          // Handle login failure (e.g., redirect to login page)
          this.$router.push('/login');
        }
      } else {
        console.error('Missing SSO parameters');
        // You might want to add more detailed logging here
        console.log('URL:', window.location.href);
        console.log('Decoded URL:', decodedUrl);
        console.log('Search params:', urlParams.toString());
        this.$router.push('/login');
      }
    }
  },
  mounted() {
    this.handleSSOCallback();
  }
};
</script>